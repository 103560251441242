import { useCallback, useEffect, useState } from 'react'
import { ListingLayoutProps } from 'shared/components/listing-layout'
import { DataWithPaginationInterface, OrderEnum } from 'shared/types/pagination-interface'

interface UseListingFilterInterface<T extends DataWithPaginationInterface<T['items'][0]>, K, R> {
  filterData: ListingLayoutProps<T, K, R>['filterData']
}

export const useListingFilter = <T extends DataWithPaginationInterface<T['items'][0]>, K, R>({
  filterData,
}: UseListingFilterInterface<T, K, R>) => {
  const [isFilterShown, setIsFilterShown] = useState(false)
  const [tempState, setTempState] = useState<K | undefined>(
    filterData ? filterData.filterer.filter : undefined,
  )
  const [isDefaultValuesFetching, setIsDefaultValuesFetching] = useState(false)

  const handleFilter = useCallback(() => {
    if (!filterData || !tempState) return
    filterData.paginator?.setPagination({ startFromId: undefined, order: OrderEnum.next })
    filterData.limiter?.setLimit(10)
    filterData.filterer.setFilter(tempState)
  }, [filterData, tempState])

  useEffect(() => {
    if (tempState && filterData && filterData.filterer.setResponseFilter) {
      filterData.filterer.setResponseFilter(tempState)
    }
  }, [filterData, tempState])

  useEffect(() => {
    if (filterData) {
      const noRenderArr = filterData.filterable
        .filter(el => el.shouldRender === false)
        .map(el => el.field)

      setTempState(prev => {
        noRenderArr.forEach(el => {
          if (prev) prev[el] = filterData.filterer.defaultFilters[el]
        })
        return prev
      })
    }
  }, [filterData])

  useEffect(() => {
    setTempState(filterData?.filterer.filter)
  }, [filterData?.filterer.filter])

  return {
    isFilterShown,
    setIsFilterShown,
    tempState,
    setTempState,
    isDefaultValuesFetching,
    setIsDefaultValuesFetching,
    handleFilter,
  }
}
