import React from 'react'

function FilterIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7608 0.608398H1.19002C0.382658 0.608398 -0.0247321 1.588 0.547328 2.16007L7.55151 9.16531V16.9692C7.55151 17.2658 7.69622 17.5438 7.93921 17.7139L10.969 19.834C11.5667 20.2524 12.3992 19.8283 12.3992 19.0893V9.16531L19.4035 2.16007C19.9744 1.58915 19.5698 0.608398 18.7608 0.608398Z"
      />
    </svg>
  )
}

export default FilterIcon
