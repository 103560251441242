import React, { useCallback, useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { PrimaryButton } from 'shared/components/button'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useWindowSize from 'shared/hooks/use-window-size'
import { deepCompare } from 'shared/utils/deepCompare'
import { formatStrWithCapitalFirstLetter } from 'shared/utils/format-string-with-capital-first-letter'
import { BadRequest } from '../errors/bad-request'
import { InternalError } from '../errors/internal-error'
import { FieldErrorAndDescription } from './form/field-error-and-description'
import { Loader } from './loader'
import Modal, { ModalSizeEnum } from './modal'

export type CreateDataType<T> = { [Property in keyof T]: T[Property] }

type PropUpdate<T> = {
  [K in keyof T]: {
    field: K
    onRender: (
      value: T[K],
      onChange: (value: T[K]) => void,
      state: T,
      onChangeState: (state: T) => void,
    ) => JSX.Element
    shouldRender?: (state: CreateDataType<T>) => boolean
  }
}[keyof T]

export type CreatableItemType<T> = PropUpdate<T> | PropUpdate<T>[]

export interface CreateModalProps<T> {
  opened: boolean
  onClose: () => void
  afterLeave?: () => void
  defaultValues: T
  caption?: string
  fullCaption?: string
  buttonCaption?: string
  modalClassName?: string
  additionalInfo?: string | JSX.Element
  modalDescription?: React.ReactNode
  needToast?: boolean
  toastCaption?: string | JSX.Element
  creatable: CreatableItemType<T>[]
  onCreate: (data: CreateDataType<T>) => Promise<void>
  isSmall?: boolean
  isPrefetching?: boolean
  requiredFields: Array<keyof T>
  saveDataAttribute?: string
  dataTestKey?: string
}

const title = 'global.create'

function CreateModal<T extends CreateDataType<T>>({
  opened,
  onClose,
  afterLeave,
  creatable,
  caption,
  fullCaption,
  buttonCaption,
  modalClassName,
  additionalInfo,
  modalDescription,
  needToast = true,
  toastCaption,
  onCreate,
  defaultValues,
  isSmall = true,
  isPrefetching,
  requiredFields,
  saveDataAttribute,
  dataTestKey,
}: CreateModalProps<T>) {
  const { t } = useLocoTranslation()
  const [error, setError] = useState('')
  const { width } = useWindowSize()
  const [required, setRequired] = useState(requiredFields)
  const [isDisabled, setIsDisabled] = useState(true)

  const initValues = useRef(defaultValues)
  const creatableRef = useRef(creatable)

  const setDefaultValues = useCallback((): T => {
    const obj: T = {} as T
    creatableRef.current.forEach(value => {
      if (Array.isArray(value)) {
        value.forEach(el => {
          obj[el.field] = defaultValues[el.field]
        })
      } else {
        obj[value.field] = defaultValues[value.field]
      }
    })
    return obj
  }, [defaultValues])
  const [tempState, setTempState] = useState<T>(setDefaultValues())

  useEffect(() => {
    setTempState(setDefaultValues())
  }, [setDefaultValues])

  useEffect(() => {
    setIsDisabled(
      !required.every(field =>
        Number.isNaN(initValues.current[field])
          ? !Number.isNaN(tempState[field])
          : !deepCompare(tempState[field], initValues.current[field]),
      ),
    )
  }, [required, tempState])

  useEffect(() => {
    const tempArr: (keyof T)[] = []
    creatableRef.current.forEach(value => {
      if (Array.isArray(value)) {
        value.forEach(el => {
          const isRequired = el.shouldRender
            ? el.shouldRender(tempState as CreateDataType<T>)
            : requiredFields.includes(el.field)
          isRequired && tempArr.push(el.field)
        })
      } else {
        const isRequired = value.shouldRender
          ? value.shouldRender(tempState as CreateDataType<T>)
          : requiredFields.includes(value.field)
        isRequired && tempArr.push(value.field)
      }
    })
    setRequired(tempArr)
  }, [requiredFields, tempState])

  const clearModal = () => {
    setTempState(setDefaultValues())
    setError('')
  }

  const [isFetching, setIsFetching] = useState(false)

  const handleConfirm = async () => {
    try {
      setError('')
      const data = Object.keys(tempState)
        .filter(
          key =>
            required.includes(key as keyof T) ||
            tempState[key as keyof T] !== defaultValues[key as keyof T],
        )
        .reduce((cur, key) => {
          return Object.assign(cur, { [key]: tempState[key as keyof T] })
        }, {})
      setIsFetching(true)
      await onCreate(data as CreateDataType<T>)
      setIsFetching(false)
      onClose()
      needToast &&
        toast.success(toastCaption ?? t('dashboard.actions.created', { module: caption }))
    } catch (e) {
      setIsFetching(false)
      if (e instanceof BadRequest) {
        if (e.errors.common) {
          setError(e.errors.common.join('<br />'))
        }
      } else if (e instanceof InternalError) {
        setError(t('core.error.internal_error_message'))
      } else {
        toast.error(t('global.error'))
      }
    }
  }

  return (
    <>
      <Modal
        className={modalClassName}
        opened={opened}
        onClose={onClose}
        title={`${
          fullCaption
            ? fullCaption
            : caption
            ? formatStrWithCapitalFirstLetter(
                t('dashboard.actions.create', {
                  module: caption,
                }),
              )
            : t(title)
        }`}
        size={isSmall ? ModalSizeEnum.small : ModalSizeEnum.medium}
        isFetching={isFetching}
        afterLeave={() => {
          clearModal()
          afterLeave && afterLeave()
        }}
        data-test={dataTestKey ? `${dataTestKey}-create-modal` : 'create-modal'}
      >
        <form className="flex flex-col gap-6 lg:gap-10">
          <div className="flex flex-col gap-5">
            {isPrefetching ? (
              <Loader className="mx-auto" />
            ) : (
              creatable.map((created, idx) => {
                if (Array.isArray(created)) {
                  return width < 1024 ? (
                    created.map(
                      el =>
                        (el.shouldRender
                          ? el.shouldRender(tempState as CreateDataType<T>)
                          : true) &&
                        el.onRender(
                          tempState[el.field],
                          value => {
                            setTempState(prev => ({ ...prev, [el.field]: value }))
                          },
                          tempState,
                          newState => setTempState(newState),
                        ),
                    )
                  ) : (
                    <div
                      key={`${idx}-wrapper`}
                      className="flex justify-between items-start [&>*]:flex-1 gap-10"
                    >
                      {created.map(
                        el =>
                          (el.shouldRender
                            ? el.shouldRender(tempState as CreateDataType<T>)
                            : true) &&
                          el.onRender(
                            tempState[el.field],
                            value => {
                              setTempState(prev => ({ ...prev, [el.field]: value }))
                            },
                            tempState,
                            newState => setTempState(newState),
                          ),
                      )}
                    </div>
                  )
                } else {
                  return (
                    (created.shouldRender
                      ? created.shouldRender(tempState as CreateDataType<T>)
                      : true) &&
                    created.onRender(
                      tempState[created.field],
                      value => {
                        setTempState(prev => ({ ...prev, [created.field]: value }))
                      },
                      tempState,
                      newState => setTempState(newState),
                    )
                  )
                }
              })
            )}
          </div>
          {!!modalDescription && modalDescription}
          <div className="flex justify-center">
            <PrimaryButton
              disabled={isDisabled}
              width="large"
              onClick={async e => {
                e.preventDefault()
                await handleConfirm()
              }}
              isFetching={isFetching}
              type="submit"
              data-test={
                dataTestKey
                  ? `${dataTestKey}-create-modal-submit-button`
                  : 'create-modal-submit-button'
              }
              {...(saveDataAttribute ? { ['data-test-element']: saveDataAttribute } : {})}
            >
              {buttonCaption ? buttonCaption : t('global.save')}
            </PrimaryButton>
          </div>
          <FieldErrorAndDescription
            error={error}
            errorClassName={'text-center'}
            description={additionalInfo}
            descriptionClassName={'flex w-full justify-center'}
          />
        </form>
      </Modal>
    </>
  )
}

export default CreateModal
